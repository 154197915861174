@import '@hh.ru/magritte-design-tokens/base-variables';
@import '@hh.ru/magritte-ui/breakpoints';

.container {
    display: none;
    flex-wrap: wrap;
    gap: @magritte-static-space-400 @magritte-static-space-600;

    .screen-gt-s({
        display: flex;
    });
}
