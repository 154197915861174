@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

.content {
    min-width: 720px;
    @media @screen-lt-m {
        min-width: unset;
    }
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: calc(@spacing-base * 2);
}

.row {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    @media @screen-lt-m {
        flex-direction: column;
    }
}

.stack {
    display: flex;
    flex-direction: column;
    gap: calc(@spacing-base * 2);
    justify-content: space-between;
}

.space {
    flex: 1;
}

.not-mobile {
    @media @screen-lt-m {
        display: none;
    }
}

.resume-footer {
    display: flex;
    gap: calc(@spacing-base * 2);
    justify-content: space-between;
    @media @screen-lt-m {
        flex-direction: column-reverse;
    }
}
