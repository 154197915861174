@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/media/_screen';

.wrapper {
    background: @color-white;
    position: relative;
}

.wrapper-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @floating;
    box-shadow: 0 4px 12px fade(@color-special-absolute-black, 15%);
}

.content {
    padding: 23px 0;
    display: flex;
    gap: 12px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media @screen-gt-xs {
        flex-direction: row;
        align-items: center;
        padding: 14px 0;
        gap: 24px;
    }
}

.text {
    flex: 1;
}

.text_desktop {
    display: none;
    @media @screen-gt-xs {
        display: block;
    }
}

.text_mobile {
    display: block;
    @media @screen-gt-xs {
        display: none;
    }
}

.buttons {
    display: flex;
    gap: 8px;
    flex-direction: column-reverse;
    justify-content: center;
    @media @screen-gt-xs {
        flex-direction: row;
    }
}
