@import '@hh.ru/magritte-ui/breakpoints';
@import '../../../../style-mixins/hidden-utils';
@import '../../../../bloko/common/styles/_layers';
@import '../../../../style-mixins/supernova-variables';

.footer-services {
    padding: 45px 0 25px;
    background: @supernova-footer-services-background-color;
    position: relative;
}

.footer-services_hide-mobile {
    .hidden-xs();
}

.service-card-wrapper {
    .screen-gt-s({
        display: flex;
        height: 100%;
    });
}
