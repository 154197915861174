@import '@hh.ru/magritte-ui/tokens';

@help-modal-padding: 32px;

@magritte-modal-width: 720px;

// Эти стили - экспериментальный код, чтобы низ Modal был покрашен до самых краев не учитывая паддинги Modal
// исправить после выхода https://jira.hh.ru/browse/PORTFOLIO-31781, https://jira.hh.ru/browse/PORTFOLIO-31150
.help-modal-left-buttons {
    background-color: @magritte-color-background-secondary;
    width: calc(@magritte-modal-width - 2 * @help-modal-padding);
    padding: 24px @help-modal-padding;
    margin: -@help-modal-padding 0 -@help-modal-padding -@help-modal-padding;

    display: flex;
    justify-content: center;
    gap: 32px;
}

.help-modal-bottom-sheet-buttons {
    background-color: @magritte-color-background-secondary;
    padding: @help-modal-padding @help-modal-padding 16px;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.help-modal-phone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
