.footer-icon {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 14px;
    margin-left: 8px;
    background-repeat: no-repeat;
}

.footer-icon_lang-en {
    background-image: url('images/lang-en.svg');
}

.footer-icon_lang-ru {
    background-image: url('images/lang-ru.svg');
}

.footer-icon_lang-ua {
    background-image: url('images/lang-ua.svg');
}

.footer-icon_lang-kz {
    background-image: url('images/lang-kz.svg');
}

.footer-icon_lang-uz {
    background-image: url('images/lang-uz.svg');
}

.footer-icon_lang-az {
    background-image: url('images/lang-az.svg');
}

.footer-icon_lang-kg {
    background-image: url('images/lang-kg.svg');
}

.footer-icon_employer {
    background-image: url('images/people.svg');
}
