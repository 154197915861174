@import '../../../../bloko/common/styles/colors';
@import '../../../../bloko/common/styles/media/_screen';

.counter {
    opacity: 0.5;
    color: @color-gray-80;
    margin-bottom: 5px;

    @media @screen-lt-s {
        margin-bottom: 20px;
    }
}
