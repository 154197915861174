@import '~bloko/common/styles/_column-variables';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/rounded-corner-base';

.offers-content-wrapper {
    padding: 0 16px;
    border-radius: @rounded-corner-default;
    box-shadow: 0 2px 10px fade(@color-gray-70, 10%), inset 0 0.5px 1px fade(@color-gray-70, 10%);
    cursor: pointer;
}

.offers-content-wrapper_selected {
    background-color: @color-blue-10;
}

.offers-content-text-container {
    margin-right: 8px;
}

.offers-content-flexbox {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.offers-content-vacancies {
    @media @screen-gt-xs {
        overflow: hidden;
        max-width: @column-width * 6 - 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.offers-content-logo-wrapper {
    margin-left: auto;
    flex: 0;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 116px;
}

.offers-content-logo {
    max-width: 100%;
    max-height: 100%;
}
