@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/_layers';
@import '~bloko/common/styles/media/_screen';

.wrapper {
    background: @color-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: @floating;
    box-shadow: 0 4px 12px fade(@color-special-absolute-black, 15%);
}

.content {
    padding: 20px 0;
    display: flex;
    gap: 24px;
    flex-direction: column;
    @media @screen-gt-xs {
        flex-direction: row;
        align-items: center;
        padding: 14px 0;
    }
}

.text {
    flex: 1;
}

.buttons {
    display: flex;
    gap: 8px;
    flex-direction: column-reverse;
    @media @screen-gt-xs {
        flex-direction: row;
    }
}
