@import '../../../bloko/common/styles/colors';

.important-notifications {
    background: @color-red-60;
    color: @color-white;
}

.important-notifications-kind-warning {
    background-color: @color-red-40;
}

.important-notifications-kind-ok {
    background-color: @color-blue-40;
}
