@import '@hh.ru/magritte-ui/breakpoints';
@import '@hh.ru/magritte-ui/tokens';

.footer-app-banner-redesign {
    display: flex;
    width: 100%;
    background-color: @magritte-color-component-card-background-neutral;
    justify-content: center;
    align-items: stretch;
    padding-top: 32px;

    .screen-gt-s ({
        height: 244px;
        margin-top: 40px;
        padding-top: 0;
    });
}

.footer-app-banner-redesign-container {
    height: 100%;
    display: flex;
    justify-content: center;
}

.footer-app-banner-redesign-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .screen-gt-s ({
        flex-direction: row;
    });
}

.footer-app-banner-redesign-qr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 32px;
    margin-bottom: 32px;
    flex-direction: column;

    .screen-gt-s ({
        justify-content: space-between;
        margin-bottom: 0;
    });

    .screen-gt-xs ({
        flex-direction: row;
    });
}

.footer-app-banner-redesign-qr-image-container {
    width: 148px;
    height: 148px;
    background: @magritte-color-background-primary;
    box-shadow: @magritte-shadow-level-1-x @magritte-shadow-level-1-y @magritte-shadow-level-1-blur
        @magritte-shadow-level-1-spread @magritte-shadow-level-1-color;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
}

.footer-app-banner-redesign-qr-image {
    width: 124px;
    height: 124px;
}

.footer-app-banner-redesign-phone-image-container {
    position: relative;
    min-height: 100%;
    display: flex;
    justify-content: center;
    .screen-gt-s ({
        display: block;
    });
}

.footer-app-banner-redesign-phone-image {
    width: 288px;

    .screen-gt-s ({
        position: absolute;
        bottom: 0;
        right: 0;
    });
}

.footer-app-banner-redesign-store {
    .screen-gt-s ({
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        flex: 1;
    });
}

.footer-app-banner-redesign-store-buttons {
    width: 100%;
    max-width: 464px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
