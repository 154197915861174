@import '../../../../bloko/common/styles/media/_screen';

.settings-button-wrapper {
    min-width: 68px;

    @media @screen-gt-xs {
        display: none;
    }
}

.resume-search-form {
    display: flex;
}
