@import '@hh.ru/magritte-design-tokens/tokens';

.link-secondary {
    --magritte-ui-text-color-override: @magritte-color-text-secondary;

    &:hover {
        --magritte-ui-text-color-override: @magritte-color-component-link-text-state-neutral-hovered;

        cursor: pointer;
    }
}

.link-secondary-contrast {
    --magritte-ui-text-color-override: @magritte-color-component-action-text-contrast-secondary;

    &:hover {
        --magritte-ui-text-color-override: @magritte-color-component-link-text-state-neutral-hovered;

        cursor: pointer;
    }
}
