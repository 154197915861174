@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttons-horizontal-wrapper {
    display: flex;
    flex-flow: wrap;
    gap: 12px;
}

.success-icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @color-green-60;
    border-radius: 100%;
}

.buttons-container {
    @media @screen-gt-xs {
        display: flex;
    }
}

.switch-container {
    @media @screen-lt-s {
        text-align: center;
    }
}

.email-error {
    color: @color-red-60;
}
