@import '../../../bloko/common/styles/colors';
@import '../../../bloko/common/styles/media/_screen';
@import '../../../bloko/common/styles/text-base';

@tip-helper-margin: 10px;

.loyalty-survey-modal-body {
    flex-grow: 1;
    width: auto;
    height: auto;

    @media @screen-gt-xs {
        width: 470px;
        min-height: 220px;
    }
}

.loyalty-survey-modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media @screen-lt-s {
        flex-direction: column;
    }
}

.loyalty-survey-modal-footer-link {
    display: flex;
    flex: 50%;

    @media @screen-lt-s {
        padding: 30px;
    }
}

.loyalty-survey-modal-footer-buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    @media @screen-lt-s {
        flex-direction: column-reverse;
        width: 100%;
    }
}

.loyalty-survey-modal-footer-tip-helper {
    margin-left: @tip-helper-margin;

    @media @screen-lt-s {
        margin-left: 0;
        margin-bottom: @tip-helper-margin;
    }
}
