@import '@hh.ru/magritte-ui/breakpoints';

@action-padding: 8px;
@actions-block-padding: 16px;
@icon-width: 24px;

// Этот стиль - экспериментальный код, чтобы заголовок и подзаголовок Modal центрировались вне зависимости от блока actions
// исправить после выхода https://jira.hh.ru/browse/PORTFOLIO-31781, https://jira.hh.ru/browse/PORTFOLIO-31150
.title-container {
    .screen-gt-s ({
        padding-left: @actions-block-padding + 2 * @action-padding + @icon-width;
    });
}
