@import '../../../bloko/common/styles/media/_screen';

.employer-zp-onboarding-footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employer-zp-onboarding-footer-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.employer-zp-onboarding-image {
    width: 640px;
    height: 320px;
    background-repeat: no-repeat;
}

.employer-zp-onboarding-image_start {
    width: 636px;
    height: 266px;
    background-image: url('start.svg');
    margin-bottom: 54px;
}

.employer-zp-onboarding-image_active-vacancies {
    background-image: url('active-vacancies.svg');

    @media @screen-lt-m {
        background-image: url('active-vacancies-tablet.svg');
    }
}

.employer-zp-onboarding-image_active-vacancies-more {
    background-image: url('active-vacancies-more.svg');
}

.employer-zp-onboarding-image_archived-vacancies {
    background-image: url('archived-vacancies.svg');

    @media @screen-lt-m {
        background-image: url('archived-vacancies-tablet.svg');
    }
}

.employer-zp-onboarding-image_orders {
    background-image: url('orders.svg');

    @media @screen-lt-m {
        background-image: url('orders-tablet.svg');
    }
}

.employer-zp-onboarding-image_closing-docs {
    background-image: url('closing-docs.svg');

    @media @screen-lt-m {
        background-image: url('closing-docs-tablet.svg');
    }
}

.employer-zp-onboarding-image_subscriptions {
    background-image: url('subscriptions.svg');

    @media @screen-lt-m {
        background-image: url('subscriptions-tablet.svg');
    }
}

.employer-zp-onboarding-image_favourites {
    background-image: url('favourites.svg');

    @media @screen-lt-m {
        background-image: url('favourites-tablet.svg');
    }
}

.employer-zp-onboarding-image_end {
    background-image: url('end.svg');
    margin-bottom: 18px;
}
