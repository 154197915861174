@import '../../../../bloko/common/styles/_abstract-modal';
@import '../../../../bloko/common/styles/_abstract-icon';
@import '../../../../bloko/common/styles/_abstract-control';
@import '../../../../bloko/blocks/suggest/variables';

.modal-header {
    display: flex;
}

.back-icon-wrapper {
    margin: auto 0;
}

.input-wrapper {
    margin-top: -(@modal-padding-xs / 2);
}

.content-wrapper {
    margin-left: @icon-size-24 + @control-padding-horizontal - @suggest-item-padding;
}
