@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.supernova-navi-search-region {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.region-link-wrapper {
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 65px;
    white-space: nowrap;
    color: @magritte-color-component-link-text-accent;
}

.supernova-navi-search {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s linear;
}

.supernova-navi-search_expanded {
    max-height: 314px;
}

.supernova-navi-search_hidden-xs {
    display: none;

    .screen-gt-xs({
        display: block;
    });
}
