@import '../../../bloko/common/styles/media/_screen';
@import '../../../bloko/common/styles/colors';

.employer-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 34px;
    line-height: 1.3;
}

.employer-info-button {
    margin-top: 13px;

    @media @screen-gt-xs {
        text-align: right;
        margin: 0;
    }
}
