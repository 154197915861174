@import '@hh.ru/magritte-ui/breakpoints';

.help-card-container {
    display: flex;
}

.help-button-links-container {
    display: grid;
    gap: 12px;

    .screen-gt-s ({
        grid-template-columns: repeat(2, 1fr);
    });
}

.help-button-link-container-wide {
    .screen-gt-s ({
        grid-column: span 2;
    });
}
